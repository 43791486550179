<template>
    <div>
          <b-modal title="ADD" id="addProductmodel" size="xl" no-close-on-esc hide-footer @hidden="addProductmodel={}">
           <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input name="SellingPrice" label="Selling Price" v-model="addProductmodel.selling_price" :rules="rules.selling_price" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="DisplayPrice" label="Display Price" v-model="addProductmodel.display_price" :rules="rules.display_price" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6 d-none">
                            <validated-input name="productmasterid" label="product master" v-model="addProductmodel.product_master_id" :rules="rules.product_master_id"/>
                        </div>
                        <div class="col-md-6 d-none">
                            <validated-input name="categoryid" label="category" v-model="addProductmodel.category_id" :rules="rules.category_id"/>
                        </div>
                        <div class="col-md-6 d-none">
                            <validated-input name="shopid" label="Shop" v-model="addProductmodel.shop_id" :rules="rules.shop_id"/>
                        </div>
                        <div class="col-md-12">
                            <b-button @click="AddProductToShop" variant="success">
                                ADD
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="mt-4 responsive-table">
            <vue-table :data="data" :fields="fields" :url="url" ref="table" :per-page="5">
                <template #image="{rowData}">
                    <img :src="rowData.icon_path" style="max-width: 50px"
                         class="rounded-circle" alt="">
                </template>
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="primary" @click="AddItem(rowData)">Add to Shop</b-button>
                    </b-button-group>
                </template>
            </vue-table>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'ChooseProduct',
    components: { },
    data () {
        return {
            product: '',
            user: [],
            addProductmodel: {
                id: '',
                product_master_id: '',
                shop_id: '',
                category_id: '',
                display_price: '',
                selling_price: ''
            },
            model: {
                id: this.$route.params.id,
                name: '',
                description: '',
                category_id: '',
                unit_price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category_id: {
                    required: true
                },
                unit_price: {
                    required: true
                },
                display_price: {
                    required: true
                },
                selling_price: {
                    required: true
                },
                product_master_id: {
                    required: true
                }
            },
            // need to update //
            url: urls.shopProduct.productlist + '?id=' + this.$route.params.id,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'category_id',
                    title: 'category_id',
                    sortField: 'category_id'
                },
                {
                    name: 'unit_price',
                    title: 'unit_price',
                    sortField: 'unit_price'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        openAddProductModal () {
            this.$bvModal.show('addProductmodel');
        },

        setData (response) {
            this.$refs.table.refreshTable();
        },
        async loadData () {
            const response = await axios.post(urls.shopProduct.productlist + '?id=' + this.$route.params.id);
            console.log('response:>', response.data);
            this.setData(response);
        },
        async AddProductToShop () {
            let url;
            if (this.addProductmodel.id) {
                url = urls.shopProduct.update;
            } else {
                url = urls.shopProduct.create;
            }

            const response = await axios.form(url, this.addProductmodel);
            // this.setBasicData(response);
            this.user.push(response.data.obj);
            this.$bvModal.hide('addProductmodel');
            this.$bvModal.hide('modal');
            this.$emit('success');
        },
        async onSubmit () {
            let url;
            if (this.model.id) {
                // need to update the url //
                url = urls.shopProduct.update;
            } else {
                // need to update the url //
                url = urls.shopProduct.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
                this.$bvModal.hide('modal');
            }
        },
        AddItem (rowData) {
            this.addProductmodel = {
                product_master_id: rowData.id,
                category_id: rowData.category_id,
                shop_id: this.$route.params.id
            };
            this.openAddProductModal();
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }
    }
};

</script>
