<template>
    <div>
        <b-modal title="" id="modal" size="xl" no-close-on-esc hide-footer @hidden="model={}">
            <choose-product></choose-product>
        </b-modal>

        <b-card class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">Add Products</b-button>
                <h3>Catalog</h3>
            </b-card-title>
            <div class="responsive-table">
                <vue-table :data="data" :fields="fields" :url="url" ref="table">
                    <template #image="{rowData}">
                        <img :src="rowData.icon_path" style="max-width: 50px"
                             class="rounded-circle" alt="">
                    </template>
                    <template #action="{rowData}">
                        <b-button-group>
                            <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                        </b-button-group>
                    </template>
                </vue-table>
            </div>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';
import ChooseProduct from '@/views/SuperAdmin/Shops/ShopCatalog/ChooseProduct';

export default {
    name: 'ShopCatalog',
    components: { ChooseProduct },
    data () {
        return {
            product: '',
            model: {
                id: this.$route.params.id,
                name: '',
                description: '',
                category: '',
                price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            // need to update //
            url: urls.shopProduct.shopproductlist + '?id=' + this.$route.params.id,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'description',
                    title: 'Description',
                    sortField: ''
                },
                {
                    name: 'category_id',
                    title: 'Category',
                    sortField: 'category'
                },
                {
                    name: 'selling_price',
                    title: 'Price',
                    sortField: 'price'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            this.$refs.table.refreshTable();
        },
        async loadData () {
            const response = await axios.post(urls.shopProduct.shopproductlist + '?id=' + this.$route.params.id);
            console.log('response:>', response.data);
            this.setData(response);
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                // need to update the url //
                url = urls.shopProduct.update;
            } else {
                // need to update the url //
                url = urls.shopProduct.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
                this.$bvModal.hide('modal');
            }
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                // need to update the url //
                const response = await axios.form(urls.shopProduct.delete, params);
                this.setData(response);
            }
        }
    }
};

</script>
